export const apiVersion = '/api/';

export const EmailEndPoints = {
  sendEmail: apiVersion + 'Email/SendEmail'
};

export const AuthenticationEndPoints = {
  login: apiVersion + 'User/Login',
  forgotPassword: apiVersion + 'User/RecoverPassword/{email}'
};

export const ClientEndPoints = {
  client: apiVersion + 'Client',
  crudClient: apiVersion + 'Client/{clientId}'
};

export const CompanyEndPoints = {
  company: apiVersion + 'Company',
  crudCompany: apiVersion + 'Company/{companyId}'
};

export const ClinicEndPoints = {
  getClinicById: apiVersion + 'Clinic/GetClinicById/',
  updateClinic: apiVersion + 'Clinic/',
  saveClinic: apiVersion + 'Clinic',
  deleteClinic: apiVersion + 'Clinic/',
  getClinicList: apiVersion + 'Clinic',
  getClinicListPaginated: apiVersion + 'Clinic/ClinicListWithPagination',
  clinicListExport: apiVersion + '/Clinic/ClinicListExport',
  getDoctorListByClinicId: apiVersion + 'Clinic/GetClinicWithDoctorListByClinicId/'
};


export const DoctorClinicEndPoints = {
  updateDoctorClinicAndClinic: apiVersion + 'DoctorClinic/UpdateDoctorClinicAndClinic',
  saveClinicTimeTable: apiVersion + 'ClinicTimeTable',
  deleteClinicTimeTable: apiVersion + 'ClinicTimeTable/',
  updateClinicTimeTable: apiVersion + 'ClinicTimeTable/{id}',
  getDoctorClinicListByDoctorId: apiVersion + 'DoctorClinic/GetDoctorClinicListByDoctorId/',
  getDoctorClinicByDoctorId: apiVersion + 'DoctorClinic/GetDoctorClinicByDoctorId/{doctorId}',
  saveDoctorClinic: apiVersion + 'DoctorClinic',
  updateClinic: apiVersion + 'DoctorClinic/{clinicId}',
  saveDoctorClinicAndClinic: apiVersion + 'DoctorClinic/SaveDoctorClinicAndClinic',
};

export const UserEndPoints = {
  userList: apiVersion + 'User/List',
  userSave: apiVersion + 'User/SaveUser',
  updateUser: apiVersion + 'User/UpdateUser',
  deleteUser: apiVersion + 'User/{userID}',
  createPassword: apiVersion + 'User/CreatePassWord',
  userLogListPagination: apiVersion + '/User/UserLogcListWithPagination',
  userLogListExport: apiVersion + '/User/UserLogListExport',

};

export const UserRoleEndPoints = {
  userRoles: apiVersion + 'UserRole',
  saveUserRole: apiVersion + 'UserRole',
  updateUserRole: apiVersion + 'UserRole/',
  deleteUserRole: apiVersion + 'UserRole/'
};

export const DoctorEndPoints = {
  doctorList: apiVersion + 'Doctor/List',
  doctorListPagination: apiVersion + 'Doctor/DoctorPagination',
  addDoctor: apiVersion + 'Doctor',
  updateDoctorGeneric: apiVersion + 'Doctor/{id}',
  getDoctorData: apiVersion + 'Doctor/GetById/{id}',
  provinceList: apiVersion + 'Province',
  townList: apiVersion + 'Town',
  nationalityList: apiVersion + 'Nationality',
  imageUpload: apiVersion + 'FileUpload/file',
  getDoctorList: apiVersion + 'Doctor',
  getDoctorSearchData: apiVersion + 'Doctor/GetDoctorSearchData/',
  getLatestCollegiateNumberAccordingToPostalcode: apiVersion + 'Doctor/GetLatestCollegiateNumberAccordingToPostalcode/',
  generateDoctorsListExcel: apiVersion + 'Doctor/GenerateDoctorsListExcel',
  getDoctorStatistics: apiVersion + 'Doctor/GetDoctorStatistics',
  exportDoctorStatistics: apiVersion + 'Doctor/ExportDoctorStatistics',
  updateDoctorSendCorrespondenceType: apiVersion + 'Doctor/UpdateDoctorSendCorrespondenceType',
};

export const DoctorBillingEndPoints = {
  doctorBillingList: apiVersion + 'DoctorBilling',
  crudDoctorBilling: apiVersion + 'DoctorBilling/{DoctorBillingId}',
  doctorBillingListByDoctorId: apiVersion + 'DoctorBilling/GetDoctorBillingListByDoctorId/{doctorId}'
};

export const DoctorBillingBankDetailEndPoints = {
  doctorBillingBankDetails: apiVersion + 'DoctorBillingBankDetail',
  saveDoctorBillingBankDetail: apiVersion + 'DoctorBillingBankDetail',
  updateDoctorBillingBankDetail: apiVersion + 'DoctorBillingBankDetail/',
  deleteDoctorBillingBankDetail: apiVersion + 'DoctorBillingBankDetail/'
};

export const DoctorCurriculumEndPoints = {
  getCurriculumByDoctorId: apiVersion + 'DoctorCurriculum/GetDoctorCurriculumByDoctorId/',
  updateDoctorCurriculum: apiVersion + 'DoctorCurriculum/UpdateDoctorCurriculum/{id}',
  getDoctorCurriculumById: apiVersion + 'DoctorCurriculum/GetDoctorCurriculumById/',
  saveDoctorCurriculum: apiVersion + 'DoctorCurriculum',
};

export const DoctorSanctionsEndPoints = {
  getDoctorSanctionsListByDoctorId: apiVersion + 'DoctorSanctions/GetDoctorSanctionsListByDoctorId/',
  updateDoctorSanctions: apiVersion + 'DoctorSanctions/',
  getDoctorSanctionsById: apiVersion + 'DoctorSanctions/GetDoctorSanctionsById/',
  saveDoctorSanctions: apiVersion + 'DoctorSanctions',
  deleteDoctorSanctions: apiVersion + 'DoctorSanctions/'
};

export const CouncilResolutionEndPoints = {
  getCouncilResolutionListByDoctorId: apiVersion + 'CouncilResolution/GetCouncilResolutioByDoctorId/',
  updateCouncilResolution: apiVersion + 'CouncilResolution/',
  getCouncilResolutionById: apiVersion + 'CouncilResolution/GetCouncilResolutionById/',
  saveCouncilResolution: apiVersion + 'CouncilResolution',
  deleteCouncilResolution: apiVersion + 'CouncilResolution/',
  getCouncilResolutionList: apiVersion + 'CouncilResolution/GetCouncilResolutionList'
};

export const CouncilResolutionDetailEndPoints = {
  getCouncilResolutionDetailListByResolutionId: apiVersion + 'CouncilResolutionDetail/GetCouncilResolutionDetailListByResolutionId/',
  updateCouncilResolutionDetail: apiVersion + 'CouncilResolutionDetail/',
  saveCouncilResolutionDetail: apiVersion + 'CouncilResolutionDetail',
  deleteCouncilResolutionDetail: apiVersion + 'CouncilResolutionDetail/'
};

export const AdministrativeResourceEndPoints = {
  getAdministrativeResourceListByResolutionId: apiVersion + 'AdministrativeResource/GetAdministrativeResourceListByResolutionId/',
  updateAdministrativeResource: apiVersion + 'AdministrativeResource/',
  saveAdministrativeResource: apiVersion + 'AdministrativeResource',
  deleteAdministrativeResource: apiVersion + 'AdministrativeResource/'
};

export const DoctorHistoryEndPoints = {
  getDoctorHistoryByDoctorId: apiVersion + 'DoctorHistory/GetDoctorHistoryByDoctorId/',
  updateDoctorHistory: apiVersion + 'DoctorHistory/',
  getDoctorHistoryById: apiVersion + 'DoctorHistory/GetDoctorHistoryById/',
  saveDoctorHistory: apiVersion + 'DoctorHistory',
  deleteDoctorHistory: apiVersion + 'DoctorHistory/'
};

export const DoctorSocietyEndPoints = {
  getDoctorSocietyByDoctorId: apiVersion + 'DoctorSociety/GetDoctorSocietyByDoctorId/',
  updateDoctorSociety: apiVersion + 'DoctorSociety/',
  getDoctorSocietyById: apiVersion + 'DoctorSociety/GetDoctorSocietyById/',
  saveDoctorSociety: apiVersion + 'DoctorSociety',
  deleteDoctorSociety: apiVersion + 'DoctorSociety/'
};

export const CouncilEndPoints = {
  getCouncilList: apiVersion + 'Council',
  saveCouncil: apiVersion + 'Council',
  updateCouncil: apiVersion + 'Council/',
  getCouncilById: apiVersion + 'Council/GetCouncilById/',
  deleteCouncil: apiVersion + 'Council/'
};

export const CertificateEndPoints = {
  getCertificateList: apiVersion + 'Certificate',
  saveCertificate: apiVersion + 'Certificate',
  updateCertificate: apiVersion + 'Certificate/',
  getCertificateById: apiVersion + 'Certificate/GetCertificateById/',
  deleteCertificate: apiVersion + 'Certificate/'
};

export const DoctorTypeEndPoints = {
  getDoctorTypeList: apiVersion + 'DoctorType',
  saveDoctorType: apiVersion + 'DoctorType',
  updateDoctorType: apiVersion + 'DoctorType/',
  getDoctorTypeById: apiVersion + 'DoctorType/GetDoctorTypeById/',
  deleteDoctorType: apiVersion + 'DoctorType/'
};

export const DoctorEconomicActsEndPoints = {
  doctorEconomicActList: apiVersion + 'EconomicAct/GetEconomicActsList',
};

export const MailAttentionEndPoints = {
  getMailAttentionList: apiVersion + 'MailAttention',
  saveMailAttention: apiVersion + 'MailAttention',
  updateMailAttention: apiVersion + 'MailAttention/',
  getMailAttentionById: apiVersion + 'MailAttention/GetMailAttentionById/',
  deleteMailAttention: apiVersion + 'MailAttention/'
};

export const TownEndPoints = {
  getTownList: apiVersion + 'Town/GetTownList',
  getTownListPaginated: apiVersion + 'Town/GetTownPaginationList',
  saveTown: apiVersion + 'Town',
  updateTown: apiVersion + 'Town/',
  deleteTown: apiVersion + 'Town/',
  getTownSearchData: apiVersion + 'Town/GetTownSearchData/'
};

export const WorkingClinicEndPoints = {
  getWorkingClinicList: apiVersion + 'WorkingClinic',
  saveWorkingClinic: apiVersion + 'WorkingClinic',
  updateWorkingClinic: apiVersion + 'WorkingClinic/',
  deleteWorkingClinic: apiVersion + 'WorkingClinic/'
};

export const WorkingSectorEndPoints = {
  getWorkingSectorList: apiVersion + 'WorkingSector',
  saveWorkingSector: apiVersion + 'WorkingSector',
  updatWorkingSector: apiVersion + 'WorkingSector/',
  deleteWorkingSector: apiVersion + 'WorkingSector/'
};

export const ExclusiveEndPoints = {
  getExclusiveList: apiVersion + 'Exclusive',
  saveExclusive: apiVersion + 'Exclusive',
  updateExclusive: apiVersion + 'Exclusive/',
  getExclusiveById: apiVersion + 'Exclusive/GetExclusiveById/',
  deleteExclusive: apiVersion + 'Exclusive/'
};

export const DedicationEndPoints = {
  getDedicationList: apiVersion + 'Dedication',
  saveDedication: apiVersion + 'Dedication',
  updateDedication: apiVersion + 'Dedication/',
  getDedicationById: apiVersion + 'Dedication/GetDedicationById/',
  deleteDedication: apiVersion + 'Dedication/'
};

export const DoctorDegreeEndPoints = {
  getDoctorDegreeList: apiVersion + 'Degree',
  saveDegree: apiVersion + 'Degree',
  updateDegree: apiVersion + 'Degree/',
  deleteDegree: apiVersion + 'Degree/'
};

export const GenderEndPoints = {
  getGenderList: apiVersion + 'Gender',
  saveGender: apiVersion + 'Gender',
  updateGender: apiVersion + 'Gender/',
  deleteGender: apiVersion + 'Gender/'
};

export const SocietyUrlEndPoints = {
  getDoctorSociety: apiVersion + 'Societies',
  getSocietyList: apiVersion + 'Societies',
  saveSociety: apiVersion + 'Societies',
  updateSociety: apiVersion + 'Societies/',
  deleteSociety: apiVersion + 'Societies/'
};

export const CountryEndPoints = {
  getCountryList: apiVersion + 'Country',
  saveCountry: apiVersion + 'Country',
  updateCountry: apiVersion + 'Country/',
  getCountryById: apiVersion + 'Country/GetCountryById/',
  deleteCountry: apiVersion + 'Country/',
  getCountrySearchData: apiVersion + 'Country/GetCountrySearchData/'
};

export const ProvinceEndPoints = {
  getProvinceList: apiVersion + 'Province',
  getProvinceListPaginated: apiVersion + 'Province/GetProvincePaginationList',
  saveProvince: apiVersion + 'Province',
  updateProvince: apiVersion + 'Province/',
  getProvinceById: apiVersion + 'Province/GetProvinceById/',
  deleteProvince: apiVersion + 'Province/',
  getProvinceSearchData: apiVersion + 'Province/GetProvinceSearchData/',
  getProvinceListForStatistics: apiVersion + 'Province/GetProvincesForStatistics'
};

export const PostEndPoints = {
  getPostList: apiVersion + 'Post',
  savePost: apiVersion + 'Post',
  updatePost: apiVersion + 'Post/',
  getPostById: apiVersion + 'Post/GetPostById/',
  deletePost: apiVersion + 'Post/'
};

export const RecallTypeEndPoints = {
  getRecallTypeList: apiVersion + 'RecallType',
  saveRecallType: apiVersion + 'RecallType',
  updateRecallType: apiVersion + 'RecallType/',
  deleteRecallType: apiVersion + 'RecallType/'
};

export const RecallFormOfContactEndPoints = {
  getRecallFormOfContactList: apiVersion + 'RecallFormOfContact',
  saveRecallFormOfContact: apiVersion + 'RecallFormOfContact',
  updateRecallFormOfContact: apiVersion + 'RecallFormOfContact/',
  deleteRecallFormOfContact: apiVersion + 'RecallFormOfContact/'
};

export const CancelReasonEndPoints = {
  getCancelReasonList: apiVersion + 'TreasonForCancel',
  saveCancelReason: apiVersion + 'TreasonForCancel',
  updateCancelReason: apiVersion + 'TreasonForCancel/',
  deleteCancelReason: apiVersion + 'TreasonForCancel/'
};

export const RecallCategoryEndPoints = {
  getRecallCategoryList: apiVersion + 'RecallCategory',
  saveRecallCategory: apiVersion + 'RecallCategory',
  updateRecallCategory: apiVersion + 'RecallCategory/',
  deleteRecallCategory: apiVersion + 'RecallCategory/'
};

export const EventTypeEndPoints = {
  getEventTypeList: apiVersion + 'EventType/GetAllEventTypes',
  saveEventType: apiVersion + 'EventType',
  updateEventType: apiVersion + 'EventType/',
  deleteEventType: apiVersion + 'EventType/'
};

export const ViaTypeEndPoints = {
  getViaTypeList: apiVersion + 'ViaType',
  saveViaType: apiVersion + 'ViaType',
  updateViaType: apiVersion + 'ViaType/',
  deleteViaType: apiVersion + 'ViaType/'
};

export const PrescriptionStatusEndPoints = {
  getPrescriptionStatusList: apiVersion + 'PrescriptionStatus',
  savePrescriptionStatus: apiVersion + 'PrescriptionStatus',
  updatePrescriptionStatus: apiVersion + 'PrescriptionStatus/',
  deletePrescriptionStatus: apiVersion + 'PrescriptionStatus/'
};

export const DoctorCouncilEndPoints = {
  getDoctorCouncilByDoctorId: apiVersion + 'DoctorCouncil/GetDoctorCouncilByDoctorId/',
  getDoctorCouncilById: apiVersion + 'DoctorCouncil/GetDoctorCouncilById/',
  getCarouselData: apiVersion + 'DoctorCouncil/GetCarouselData',
  saveDoctorCouncil: apiVersion + 'DoctorCouncil',
  updateDoctorCouncil: apiVersion + 'DoctorCouncil/'
};

export const DoctorCorrespondenceEndPoints = {
  getDoctorCorrespondenceByDoctorId: apiVersion + 'DoctorCorrespondence/GetDoctorCorrespondenceByDoctorId/',
  getDoctorCorrespondenceById: apiVersion + 'DoctorCorrespondence/GetDoctorCorrespondenceById/',
  saveDoctorCorrespondence: apiVersion + 'DoctorCorrespondence',
  updateDoctorCorrespondence: apiVersion + 'DoctorCorrespondence/'
};

export const DoctorObservationsEndPoints = {
  getDoctorObservationsByDoctorId: apiVersion + 'DoctorObservations/GetDoctorObservationsByDoctorId/',
  getDoctorObservationsById: apiVersion + 'DoctorObservations/GetDoctorObservationsById/',
  saveDoctorObservations: apiVersion + 'DoctorObservations',
  updateDoctorObservations: apiVersion + 'DoctorObservations/',
  deleteDoctorObservations: apiVersion + 'DoctorObservations/'
};

export const DoctorProfessionalAddressEndPoints = {
  getDoctorProfessionalAddressByDoctorId: apiVersion + 'DoctorProfessionalAddress/GetDoctorProfessionalAddressByDoctorId/',
  getDoctorProfessionalAddressById: apiVersion + 'DoctorProfessionalAddress/GetDoctorProfessionalAddressById/',
  saveDoctorProfessionalAddress: apiVersion + 'DoctorProfessionalAddress',
  updateDoctorProfessionalAddress: apiVersion + 'DoctorProfessionalAddress/',
  deleteDoctorProfessionalAddress: apiVersion + 'DoctorProfessionalAddress/',
  updateDefaultDoctorProfessionalAddress: apiVersion + 'DoctorProfessionalAddress/UpdateDefaultDoctorProfessionalAddress/{id}/'
};

export const DoctorProfessionalEndPoints = {
  getDoctorProfessionalByDoctorId: apiVersion + 'DoctorProfessional/GetDoctorProfessionalByDoctorId/',
  getDoctorProfessionalById: apiVersion + 'DoctorProfessional/GetDoctorProfessionalById/',
  saveDoctorProfessional: apiVersion + 'DoctorProfessional',
  updateDoctorProfessional: apiVersion + 'DoctorProfessional/'
};

export const PositionsEndPoints = {
  getPositionsByDProfessionalId: apiVersion + 'Positions/GetPositionsByDProfessionalId/',
  getPositionsById: apiVersion + 'Positions/GetPositionsById/',
  savePositions: apiVersion + 'Positions',
  updatePositions: apiVersion + 'Positions/',
  getPositionsList: apiVersion + 'Positions',
  deletePositions: apiVersion + 'Positions/'
};

export const AssociatedHistoryEndPoints = {
  getAssociatedHistoryByDProfessionalId: apiVersion + 'AssociatedHistory/GetAssociatedHistoryByDProfessionalId/',
  getAssociatedHistoryById: apiVersion + 'AssociatedHistory/GetAssociatedHistoryById/',
  saveAssociatedHistory: apiVersion + 'AssociatedHistory',
  updateAssociatedHistory: apiVersion + 'AssociatedHistory/',
  getAssociatedHistoryList: apiVersion + 'AssociatedHistory',
  deleteAssociatedHistory: apiVersion + 'AssociatedHistory/'
};

export const GlobalSearchEndPoints = {
  getGlobalSearchData: apiVersion + 'GlobalSearch/GetGlobalSearchData'
};

export const RecallEndPoints = {
  getRecallById: apiVersion + 'Recall/GetRecallById/',
  saveRecall: apiVersion + 'Recall',
  updateRecall: apiVersion + 'Recall/',
  getRecallList: apiVersion + 'Recall/GetRecallList',
  deleteRecall: apiVersion + 'Recall/',
  recallListPagination: apiVersion + '/Recall/RecallListWithPagination',
  recallListExport: apiVersion + '/Recall/RecallListExport',
  getRecallRowNumberByRecallId: apiVersion + '/Recall/GetRecallRowNumberByRecallId/'
};

export const CorrespondencePoints = {
  getCorrespondenceById: apiVersion + 'Correspondence/GetCorrespondenceById/',
  getCorrespondenceList: apiVersion + 'Correspondence/GetCorrespondenceList',
  getcorrespondencesListPaginated: apiVersion + 'Correspondence/CorrespondencesListWithPagination',
  correspondenceListExport: apiVersion + 'Correspondence/CorrespondenceListExport',
  saveCorrespondence: apiVersion + 'Correspondence',
  updateCorrespondence: apiVersion + 'Correspondence/',
  deleteCorrespondence: apiVersion + 'Correspondence/',
  saveCorrespondenceDocumentRange: apiVersion + 'CorrespondenceDocument/SaveRange',
  deleteCorrespondenceDocuments: apiVersion + 'CorrespondenceDocument/DeleteCorrespondenceDocuments',
};

export const EventPoints = {
  getEventById: apiVersion + 'Event/GetEventById/',
  getEventList: apiVersion + 'Event/GetEventList',
  saveEvent: apiVersion + 'Event',
  updateEvent: apiVersion + 'Event/',
  deleteEvent: apiVersion + 'Event/',
  getEventsListWithPagination: apiVersion + 'Event/GetEventsListWithPagination',
  eventListExport: apiVersion + 'Event/EventListExport'
};

export const CourseEndPoints = {
  getCourseById: apiVersion + 'Course/GetCourseById/',
  saveCourse: apiVersion + 'Course',
  updateCourse: apiVersion + 'Course/',
  getCourseList: apiVersion + 'Course/GetCourseList',
  deleteCourse: apiVersion + 'Course/',
  getActiveCoursesList: apiVersion + 'Course/GetActiveCoursesList',
  getFinishCoursesList: apiVersion + 'Course/GetFinishCoursesList/{fromDate}/{toDate}',
  getTodayCourseModuleList: apiVersion + 'Course/GetTodayCourseModuleList',
  getNewCoursesList: apiVersion + 'Course/GetNewCoursesList',
  getFinishCoursesListWithFilter: apiVersion + 'Course/GetFinishCoursesListWithFilter/{fromDate}/{toDate}',
  getCourseListPaginated: apiVersion + 'Course/CourseListWithPagination',
  courseListExport: apiVersion + 'Course/CourseListExport',
  getCourseListWithAttendeesPagination: apiVersion + 'Course/CourseListWithAttendeesPagination',
  studentListExport: apiVersion + 'Course/CourseListWithAttendeesExport',
};

export const StudentEndPoints = {
  getStudentById: apiVersion + 'Student/GetStudentById/',
  saveStudent: apiVersion + 'Student/SaveStudent',
  updateStudent: apiVersion + 'Student/UpdateStudent',
  updateStudentGeneric: apiVersion + 'Student/',
  deleteStudent: apiVersion + 'Student/',
  getStudentListByCourseId: apiVersion + 'Student/GetStudentListByCourseId/',
  updateAttendanceAndPaymentStatus: apiVersion + 'Student/UpdateAttendanceAndPaymentStatus/{id}',
  getStudentsList: apiVersion + 'Student/GetStudentsList'
};

export const ModuleEndPoints = {
  getModuleById: apiVersion + 'CourseModule/GetCourseModuleById/',
  saveModule: apiVersion + 'CourseModule/SaveCourseModule',
  updateModule: apiVersion + 'CourseModule/',
  deleteModule: apiVersion + 'CourseModule/',
  getCourseModuleListByCourseId: apiVersion + 'CourseModule/GetCourseModuleListByCourseId/'
};

export const PriceDetailsEndPoints = {
  getPriceDetailsById: apiVersion + 'PriceDetails/GetPriceDetailsById/',
  savePriceDetails: apiVersion + 'PriceDetails/SavePriceDetails',
  updatePriceDetails: apiVersion + 'PriceDetails/UpdatePriceDetails',
  deletePriceDetail: apiVersion + 'PriceDetails/',
  savePriceList: apiVersion + 'PriceList/SavePriceList',
  getPriceListByModuleId: apiVersion + 'PriceList/GetPriceListByModuleId/{courseId}/{moduleId}/{courseTypeId}',
  deletePriceList: apiVersion + 'PriceList/',
  deletePriceAdditionalDataByPriceId: apiVersion + 'PriceAdditionalData/DeletePriceAdditionalDataByPriceId/',
};

export const PrescriptionEndPoints = {
  getPrescriptionById: apiVersion + 'Prescription/GetPrescriptionById/',
  savePrescription: apiVersion + 'Prescription',
  updatePrescription: apiVersion + 'Prescription/',
  getPrescriptionList: apiVersion + 'Prescription/GetPrescriptionWithDoctorList',
  deletePrescription: apiVersion + 'Prescription/',
  savePrescriptionFileData: apiVersion + 'Prescription',
  getPrescriptionFileDataList: apiVersion + 'Prescription/GetPrescriptionFileDataWithDoctorList',
  getPrescriptionsListWithPagination: apiVersion + 'Prescription/GetPrescriptionsListWithPagination',
  prescriptionListExport: apiVersion + 'Prescription/PrescriptionListExport'
};

export const rolePermissionsEndPoints = {
  getRolePermission: apiVersion + 'RolePermission/GetAllRolePermission',
  saveRolePermission: apiVersion + 'RolePermission/SaveRolePermission',
  saveRolePermissionListByRoleId: apiVersion + 'RolePermission/SaveRolePermissionListByRoleId/',
  assignRemoveAllPermissions: apiVersion + 'RolePermission/AssignRemoveAllPermissions/',
  saveRolePermissionListByPermissionGroupName: apiVersion + 'RolePermission/SaveRolePermissionListByPermissionGroupName/'

};

export const NationalityEndPoints = {
  getNationalityList: apiVersion + 'Nationality',
  saveNationality: apiVersion + 'Nationality',
  updateNationality: apiVersion + 'Nationality/',
  deleteNationality: apiVersion + 'Nationality/'
};

export const BankEndPoints = {
  getBankById: apiVersion + 'Bank/GetBankById/',
  updateBank: apiVersion + 'Bank/',
  saveBank: apiVersion + 'Bank',
  deleteBank: apiVersion + 'Bank/',
  getBankList: apiVersion + 'Bank/GetBankList',
  getBankDropDownList: apiVersion + 'Bank/getBankDropDownList'
};

export const BankIncomeEndPoints = {
  getBankIncomeById: apiVersion + 'BankIncome/GetBankIncomeById/',
  updateBankIncome: apiVersion + 'BankIncome/',
  saveBankIncome: apiVersion + 'BankIncome',
  deleteBankIncome: apiVersion + 'BankIncome/',
  getBanKIncomeList: apiVersion + 'BankIncome',
  getBankIncomeListWithPagination: apiVersion + 'BankIncome/BankIncomeListWithPagination'
};

export const BankExpensesEndPoints = {
  getBankExtractionById: apiVersion + 'BankExtraction/GetBankExtractionById/',
  updateBankExtraction: apiVersion + 'BankExtraction/',
  saveBankExtraction: apiVersion + 'BankExtraction',
  deleteBankExtraction: apiVersion + 'BankExtraction/',
  getBankExtractionList: apiVersion + 'BankExtraction',
  getBankExtractionListWithPagination: apiVersion + 'BankExtraction/BankExtractionListWithPagination'
};

export const SupplierEndPoints = {
  getSupplierById: apiVersion + 'Supplier/GetSupplierById/',
  updateSupplier: apiVersion + 'Supplier/',
  saveSupplier: apiVersion + 'Supplier',
  deleteSupplier: apiVersion + 'Supplier/',
  getAllSupplierListByGeneric: apiVersion + 'Supplier/',
  getSupplierListPaginated: apiVersion + 'Supplier/SupplierListWithPagination'
};

export const JudicialResolutionsEndPoints = {
  getJudicialResolutionsById: apiVersion + 'JudicialResolutions/GetJudicialResolutionsById/',
  updateJudicialResolutions: apiVersion + 'JudicialResolutions/',
  saveJudicialResolution: apiVersion + 'JudicialResolutions',
  deleteJudicialResolution: apiVersion + 'JudicialResolutions/',
  getJudicialResolutionsList: apiVersion + 'JudicialResolutions/GetJudicialResolutionsList'
};

export const JudicialResolutionIssueEndPoints = {
  getJudicialResolutionIssueList: apiVersion + 'JudicialResolutionIssue',
  saveJudicialResolutionIssue: apiVersion + 'JudicialResolutionIssue',
  updateJudicialResolutionIssue: apiVersion + 'JudicialResolutionIssue/',
  deleteTJudicialResolutionIssue: apiVersion + 'JudicialResolutionIssue/',
  getJudicialResolutionIssueById: apiVersion + 'JudicialResolutionIssue/GetJudicialResolutionIssueById/'
};

export const ProcedureEndPoints = {
  getProcedureList: apiVersion + 'Procedure'
};

export const JudicialDecisionsEndPoints = {
  getJudicialDecisionById: apiVersion + 'JudicialDecisions/GetJudicialDecisionById/',
  updateJudicialDecision: apiVersion + 'JudicialDecisions/',
  saveJudicialDecision: apiVersion + 'JudicialDecisions',
  deleteJudicialDecision: apiVersion + 'JudicialDecisions/',
  getJudicialDecisionList: apiVersion + 'JudicialDecisions'
};


export const IssuerEndPoints = {
  getAllIssuerList: apiVersion + 'Issuer',
  getIssuerList: apiVersion + 'Issuer/GetIssuerList',
  saveIssuer: apiVersion + 'Issuer',
  updateIssuer: apiVersion + 'Issuer/',
  deleteIssuer: apiVersion + 'Issuer/',
  getIssuerById: apiVersion + 'Issuer/GetIssuerById/',
  getDefaultIssuer: apiVersion + 'Issuer/GetDefaultIssuer/',
  updateDefaultIssuer: apiVersion + 'Issuer/UpdateDefaultIssuer/{id}/'
};

export const PrescriptionFileDataEndPoints = {
  getPrescriptionFileDataListByPrescriptionId: apiVersion + 'Prescription/GetPrescriptionFileDataListByPrescriptionId/',
  getPrescriptionFileData: apiVersion + 'Prescription/GetPrescriptionFileData/{id}/{name}',
  addPrescriptionFileData: apiVersion + 'Prescription/SavePrescriptionFileData',
  deletePrescriptionFileData: apiVersion + 'Prescription/DeletePrescriptionFileData/',
  uploadPrescriptionFileData: apiVersion + 'FileUpload/UploadFileData'
};

export const UploadFileDataEndPoints = {
  uploadFileData: apiVersion + 'FileUpload/UploadFileData',
  uploadDocument: apiVersion + 'FileUpload/UploadDocument'
};

export const DoctorFileDataEndPoints = {
  getDoctorFileDataListByDoctorId: apiVersion + 'Doctor/GetDoctorFileDataListByDoctorId/',
  getDoctorFileData: apiVersion + 'Doctor/GetDoctorFileData/{doctorId}/{id}/{name}',
  addDoctorFileData: apiVersion + 'Doctor/SaveDoctorFileData',
  deleteDoctorFileData: apiVersion + 'Doctor/DeleteDoctorFileData/',
  uploadDoctorFileData: apiVersion + 'FileUpload/UploadFileData'
};

export const ActivityEndPoints = {
  getActivityById: apiVersion + 'Activity/GetActivityById/',
  updateActivity: apiVersion + 'Activity/UpdateActivity',
  updateActivityByGeneric: apiVersion + 'Activity/',
  saveActivity: apiVersion + 'Activity/SaveActivity',
  deleteActivity: apiVersion + 'Activity/',
  getActivitylist: apiVersion + 'Activity/GetActivitylist'
};

export const ActivityGroupEndPoints = {
  getActivityGroupById: apiVersion + 'ActivityGroup/GetActivityGroupById/',
  updateActivityGroup: apiVersion + 'ActivityGroup/',
  saveActivityGroup: apiVersion + 'ActivityGroup',
  deleteActivityGroup: apiVersion + 'ActivityGroup/',
  getActivityGrouplist: apiVersion + 'ActivityGroup'
};

export const ActivityPriceDetailsEndPoints = {
  getActivityPriceDetailsById: apiVersion + 'ActivityPriceDetails/GetActivityPriceDetailsById/',
  updateActivityPriceDetails: apiVersion + 'ActivityPriceDetails/',
  saveActivityPriceDetails: apiVersion + 'ActivityPriceDetails',
  deleteActivityPriceDetails: apiVersion + 'ActivityPriceDetails/',
  getActivityPriceDetailslist: apiVersion + 'ActivityPriceDetails'
};

export const SeriesEndPoints = {
  getSeriesList: apiVersion + 'Series',
  saveSeries: apiVersion + 'Series',
  updateSeries: apiVersion + 'Series/',
  deleteSeries: apiVersion + 'Series/'
};
export const ExpensesEndPoints = {
  getExpensesDataByExpensesId: apiVersion + 'Expenses/GetExpensesDataByExpensesId/',
  saveExpenses: apiVersion + 'Expenses/SaveExpenses',
  updateExpenses: apiVersion + 'Expenses/UpdateExpenses',
  deleteExpenses: apiVersion + 'Expenses/',
  getAllExpensesListByGeneric: apiVersion + 'Expenses/',
  getExpensesListPagination: apiVersion + 'Expenses/ExpensesListWithPagination/',
  deleteExpenseDetailByExpensesId: apiVersion + 'ExpensesDetail/DeleteExpenseDetailByExpensesId/'
};

export const SocietyEndPoints = {
  getSocietyList: apiVersion + 'Society/GetSocietyList',
  saveSociety: apiVersion + 'Society',
  updateSociety: apiVersion + 'Society/',
  deleteSociety: apiVersion + 'Society/',
  getSocietyById: apiVersion + 'Society/GetSocietyById/',
  getSocietyListExpand: apiVersion + 'Society/GetSocietyListExpand/',
  getExportSocietyListExpand: apiVersion + 'Society/ExportSocietyListExpand/',
  getSocietyListPaginated: apiVersion + 'Society/SocietyListWithPagination'
};

export const SocietyActivityEndPoints = {
  saveSocietyActivity: apiVersion + 'SocietyActivity',
  updateSocietyActivity: apiVersion + 'SocietyActivity/',
  deleteSocietyActivity: apiVersion + 'SocietyActivity/',
  getSocietyActivityBySocietyId: apiVersion + 'SocietyActivity/GetSocietyActivityBySocietyId/'
};


export const SocietyAdministratorEndPoints = {
  saveSocietyAdministrator: apiVersion + 'SocietyAdministrator',
  updateSocietyAdministrator: apiVersion + 'SocietyAdministrator/',
  deleteSocietyAdministrator: apiVersion + 'SocietyAdministrator/',
  getSocietyAdministratorListBySocietyId: apiVersion + 'SocietyAdministrator/GetSocietyAdministratorDataBySocietyId/'
}

export const SocietyMembersEndPoints = {
  getMembersListBySocietyId: apiVersion + 'SocietyMembers/GetMembersListBySocietyId/',
  saveSocietyMembers: apiVersion + 'SocietyMembers',
  updateSocietyMembers: apiVersion + 'SocietyMembers/',
  deleteSocietyMembers: apiVersion + 'SocietyMembers/',
  getSocietyMembersById: apiVersion + 'SocietyMembers/GetSocietyMembersById/',
  getDoctorListWithPagination: apiVersion + 'SocietyMembers/GetDoctorListWithPagination'
};

export const SocietyDisabledRecordEndPoints = {
  saveSocietyDisabledRecord: apiVersion + 'SocietyDisabledRecord',
  updateSocietyDisabledRecord: apiVersion + 'SocietyDisabledRecord/',
  deleteSocietyDisabledRecord: apiVersion + 'SocietyDisabledRecord/',
  getSocietyDisabledRecordListBySocietyId: apiVersion + 'SocietyDisabledRecord/GetSocietyDisabledRecordDataBySocietyId/'
}

export const SocietySanctionsEndPoints = {
  saveSocietySanctions: apiVersion + 'SocietySanctions',
  updateSocietySanctions: apiVersion + 'SocietySanctions/',
  deleteSocietySanctions: apiVersion + 'SocietySanctions/',
  getSocietySanctionsListBySocietyId: apiVersion + 'SocietySanctions/GeSocietySanctionsDataBySocietyId/'
}

export const SocietyClinicEndPoints = {
  getSocietyClinicListBySocietyId: apiVersion + 'SocietyClinic/GetSocietyClinicListBySocietyId/',
  saveSocietyClinic: apiVersion + 'SocietyClinic',
  updateSocietyClinic: apiVersion + 'SocietyClinic/',
  deleteSocietyClinic: apiVersion + 'SocietyClinic/',
  getSocietyClinicById: apiVersion + 'SocietyClinic/GetSocietyClinicById/'
};

export const SocietyCivilLiabilityEndPoints = {
  saveSocietyCivilLiability: apiVersion + 'SocietyCivilLiability',
  updateSocietyCivilLiability: apiVersion + 'SocietyCivilLiability/',
  deleteSocietyCivilLiability: apiVersion + 'SocietyCivilLiability/',
  getSocietyCivilLiabilityDataBySocietyId: apiVersion + 'SocietyCivilLiability/GeSocietyCivilLiabilityDataBySocietyId/'
}

export const SocietyDocumentsEndPoints = {
  getSocietyDocumentsListBySocietyId: apiVersion + 'SocietyDocuments/GetSocietyDocumentsListBySocietyId/',
  getSocietyDocuments: apiVersion + 'SocietyDocuments/GetSocietyDocuments/{societyId}/{id}/{name}',
  saveSocietyDocuments: apiVersion + 'SocietyDocuments/SaveSocietyDocuments',
  deleteSocietyDocuments: apiVersion + 'SocietyDocuments/DeleteSocietyDocuments/',
  uploadSocietyDocuments: apiVersion + 'FileUpload/UploadSocietyDocuments'
}

export const SocietyObservationsEndPoints = {
  saveSocietyObservations: apiVersion + 'SocietyObservations',
  updateSocietyObservations: apiVersion + 'SocietyObservations/',
  deleteSocietyObservations: apiVersion + 'SocietyObservations/',
  getSocietyObservationsBySocietyId: apiVersion + 'SocietyObservations/GetSocietyObservationsBySocietyId/'
};

export const VatTableEndPoints = {
  getVatTableList: apiVersion + 'VatType',
  saveVatTable: apiVersion + 'VatType',
  updateVatTable: apiVersion + 'VatType/',
  deleteVatTable: apiVersion + 'VatType/',
  updateAsDefault: apiVersion + 'VatType/SaveAsDefault'
}

export const PaymentsEndPoints = {
  allPaymentList: apiVersion + 'Payment/GetPaymentList',
  paymentList: apiVersion + 'Payment/GetPaymentListByType/',
  paymentListPagination: apiVersion + 'Payment/GetPaymentListPagination/',
  doctorPaymentListPagination: apiVersion + 'Payment/GetDoctorPaymentListPagination/',
  doctorPaymentListExpand: apiVersion + 'Payment/GetDoctorPaymentListExpand/',
  doctorPaymentListExpandExport: apiVersion + 'Payment/ExportDoctorPaymentListExpand',
  getPaymentsById: apiVersion + 'Payment/GetPaymentsById/{id}',
  isDecriptionDuplicated:  apiVersion + 'Payment/IsDecriptionDuplicated/{paymentId}/{paymentType}/{description}',
  savePayment: apiVersion + 'Payment/SavePayment',
  updatePayment: apiVersion + 'Payment/UpdatePayment/{id}',
  deletePayment: apiVersion + 'Payment/DeletePayment/',
  updatePaymentStatus: apiVersion + 'Payment/UpdatePaymentStatus/{id}',
  getCloneStatus: apiVersion + 'Payment/GetCloneStatus',
  cloneQuotas: apiVersion + 'Payment/CloneQuotas/{type}/{confirmation}',
  cloneSinglePayment: apiVersion + 'Payment/CloneSinglePayment/{id}',
  GetPaymentAlerts: apiVersion + 'Payment/GetPaymentAlerts/{userId}'
};

export const DoctorPaymentsEndPoints = {
  doctorPaymentList: apiVersion + 'PaymentDoctor/GetPaymentsByDoctor',
  getPaymentDoctorById: apiVersion + 'PaymentDoctor/GetPaymentsById/',
  updateDoctorPayment: apiVersion + 'PaymentDoctor/UpdatePaymentDate/',
};

export const DoctorCoursesEndPoints = {
  doctorCourseList: apiVersion + 'Student/GetCoursesByDoctor',
};

export const ConfigurationsEndPoints = {
  getConfigurationsList: apiVersion + 'Configuration/GetConfigurationList/',
  getConfigurationsListByGroup: apiVersion + 'Configuration/GetConfigurationListByGroup/{groupName}',
  saveConfiguration: apiVersion + 'Configuration',
  updateConfiguration: apiVersion + 'Configuration/{id}',
};

export const EmailConfigsEndPoints = {
  deleteEmailConfig: apiVersion + "EmailConfig",
  editEmailConfig: apiVersion + "EmailConfig/UpdateEmailConfig",
  getEmailConfigList: apiVersion + "EmailConfig/GetEmailConfig",
  saveEmailConfig: apiVersion + "EmailConfig/SaveEmailConfig"
};


export const FiscalAccountTableEndPoints = {
  getFiscalAccountTableList: apiVersion + 'FiscalAccount',
  saveFiscalAccountTable: apiVersion + 'FiscalAccount',
  updateFiscalAccountTable: apiVersion + 'FiscalAccount/',
  deleteFiscalAccountTable: apiVersion + 'FiscalAccount/'
}

export const ExpensesTypeTableEndPoints = {
  getExpenseTypeTableList: apiVersion + 'ExpensesType',
  saveExpenseTypeTable: apiVersion + 'ExpensesType',
  updateExpenseTypeTable: apiVersion + 'ExpensesType/',
  deleteExpenseTypeTable: apiVersion + 'ExpensesType/'
}

export const ExpensesGroupEndPoints = {
  getExpensesGroupList: apiVersion + 'ExpensesGroup/',
  saveExpensesGroup: apiVersion + 'ExpensesGroup',
  updateExpensesGroup: apiVersion + 'ExpensesGroup/',
  deleteExpensesGroup: apiVersion + 'ExpensesGroup/',
  getExpensesGroupById: apiVersion + 'ExpensesGroup/GetExpensesGroupById/'
};

export const ExpenseConceptTableEndPoints = {
  getExpenseConceptTableList: apiVersion + 'ExpenseConcept/GetExpenseConceptList',
  saveExpenseConceptTable: apiVersion + 'ExpenseConcept',
  updateExpenseConceptTable: apiVersion + 'ExpenseConcept/',
  deleteExpenseConceptTable: apiVersion + 'ExpenseConcept/'
}

export const PaymentTypeEndPoints = {
  getPaymentTypeList: apiVersion + 'PaymentType/',
  savePaymentType: apiVersion + 'PaymentType',
  updatePaymentType: apiVersion + 'PaymentType/',
  deletePaymentType: apiVersion + 'PaymentType/',
  getPaymentTypeById: apiVersion + 'PaymentType/GetIssuerById/'
};

export const CompanyLogoEndPoints = {
  getCompanyLogoList: apiVersion + 'CompanyLogoConfiguration/GetCompanyLogoConfigurationList/',
  saveCompanyLogo: apiVersion + 'CompanyLogoConfiguration',
  updateCompanyLogo: apiVersion + 'CompanyLogoConfiguration/',
  deleteCompanyLogo: apiVersion + 'CompanyLogoConfiguration/',
  getCompanyLogoById: apiVersion + 'CompanyLogoConfiguration/GetIssuerById/',
  uploadCompanyLogo : apiVersion + 'FileUpload/UploadCompanyLogo'
};

export const FilterProfileEndPoints = {
  getFilterProfileById: apiVersion + 'FilterProfile/GetFilterProfileById/',
  updateFilterProfile: apiVersion + 'FilterProfile/',
  saveFilterProfile: apiVersion + 'FilterProfile',
  deleteFilterProfile: apiVersion + 'FilterProfile/',
  getFilterProfileList: apiVersion + 'FilterProfile',
  getUserFilterProfileList: apiVersion + 'FilterProfile/GetFilterProfiles',
};

export const UserActionsEndPoints = {
  getLatestDoctorViewList: apiVersion + 'UserAction/LatestDoctorViewList',
  saveUserAction: apiVersion + 'UserAction',
};

export const DbChangeHistoryEndPoints = {
  getDbChangeHistoryList: apiVersion + 'DbChangeHistory',
  saveDbChangeHistory: apiVersion + 'DbChangeHistory',
  updateDbChangeHistory: apiVersion + 'DbChangeHistory/',
  getDbChangeHistoriesByEntity: apiVersion + 'DbChangeHistory/GetDbChangeHistories/{referenceId}/{entity}',
  getDbChangeHistoriesByColumns: apiVersion + 'DbChangeHistory/GetDbChangeHistoriesByColumns/{referenceId}/{entity}/{columns}',
  deleteDbChangeHistory: apiVersion + 'DbChangeHistory/'
};

export const TseriesEndPoints = {
  getTseriesList: apiVersion + 'Tseries',
  saveTseries: apiVersion + 'Tseries',
  updateTseries: apiVersion + 'Tseries/',
  deleteTseries: apiVersion + 'Tseries/',
  getTseriesByYear: apiVersion + 'Tseries/GetByYear/{year}',
  getDistinctYearsList: apiVersion + 'Tseries/GetDistinctYearsList',
  cloneSeries: apiVersion + 'Tseries/CloneSeries/{fromYear}/{toYear}/{isCloned}',
  getSeriesCloneDetail: apiVersion + 'TseriesCloneDetail'
};

export const InvoiceEndPoints = {
  getInvoiceById: apiVersion + 'Invoice/GetInvoiceById/{id}',
  saveInvoice: apiVersion + 'Invoice/SaveInvoice',
  invoiceListPagination: apiVersion + 'Invoice/GetInvoiceListPagination',
};

export const CancelInvoiceEndPoints = {
  getCancelInvoiceById: apiVersion + 'InvoiceCancellation/GetInvoiceCancellationById/{id}',
  saveCancelInvoice: apiVersion + 'InvoiceCancellation/SaveInvoiceCancellation',
};

export const ReceiptEndPoints = {
  getReceiptById: apiVersion + 'Receipt/GetReceiptById/{id}',
  saveReceipt: apiVersion + 'Receipt/SaveReceipt',
  receiptListPagination: apiVersion + 'Receipt/GetReceiptListPagination',
};

export const CancelReceiptEndPoints = {
  getCancelReceiptById: apiVersion + 'ReceiptCancellation/GetReceiptCancellationById/{id}',
  saveCancelReceipt: apiVersion + 'ReceiptCancellation/SaveReceiptCancellation',
};

export const CollegeEndPoints = {
  getCollegeList: apiVersion + 'College',
  saveCollege: apiVersion + 'College',
  updateCollege: apiVersion + 'College/',
  getCollegeById: apiVersion + 'College/GetCollegeById/',
  deleteCollege: apiVersion + 'College/'
};

export const CollegeDocumentEndPoints = {
  getCollegeDocumentList: apiVersion + 'CollegeDocument',
  saveCollegeDocument: apiVersion + 'CollegeDocument',
  updateCollegeDocument: apiVersion + 'CollegeDocument/',
  getCollegeDocumentById: apiVersion + 'CollegeDocument/GetCollegeDocumentById/',
  deleteCollegeDocument: apiVersion + 'CollegeDocument/',
  uploadCollegeDocument: apiVersion + 'FileUpload/UploadFileData'
};

export const CollegeObservationEndPoints = {
  getCollegeObservationList: apiVersion + 'CollegeObservation',
  saveCollegeObservation: apiVersion + 'CollegeObservation',
  updateCollegeObservation: apiVersion + 'CollegeObservation/',
  getCollegeObservationById: apiVersion + 'CollegeObservation/GetCollegeObservationById/',
  deleteCollegeObservation: apiVersion + 'CollegeObservation/'
};

export const CollegeProvinceEndPoints = {
  getCollegeProvinceList: apiVersion + 'CollegeProvince',
  saveCollegeProvince: apiVersion + 'CollegeProvince',
  updateCollegeProvince: apiVersion + 'CollegeProvince/',
  getCollegeProvinceById: apiVersion + 'CollegeProvince/GetCollegeProvinceById/',
  deleteCollegeProvince: apiVersion + 'CollegeProvince/'
};

